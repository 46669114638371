import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-hillsboro-oregon.png'
import image0 from "../../images/cities/scale-model-of-welcome-to-south-hillsboro-in-hillsboro-oregon.png"
import image1 from "../../images/cities/scale-model-of-troon-fountain-west-in-hillsboro-oregon.png"
import image2 from "../../images/cities/scale-model-of-troon-foutain-east-in-hillsboro-oregon.png"
import image3 from "../../images/cities/scale-model-of-northridge-woods-natural-area-in-hillsboro-oregon.png"
import image4 from "../../images/cities/scale-model-of-jackson-bottom-wetlands-preserve-in-hillsboro-oregon.png"
import image5 from "../../images/cities/scale-model-of-rice-northwest-museum-of-rocks-&-minerals-in-hillsboro-oregon.png"
import image6 from "../../images/cities/scale-model-of-rood-bridge-park-in-hillsboro-oregon.png"
import image7 from "../../images/cities/scale-model-of-cooper-mountain-nature-park-in-hillsboro-oregon.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Hillsboro'
            state='Oregon'
            image={image}
            lat='45.528'
            lon='-122.9357'
            attractions={ [{"name": "Welcome To South Hillsboro", "vicinity": "Hillsboro", "types": ["point_of_interest", "establishment"], "lat": 45.4972173, "lng": -122.90261880000003}, {"name": "Troon Fountain West", "vicinity": "15375 NW Troon Dr, Portland", "types": ["point_of_interest", "establishment"], "lat": 45.5481845, "lng": -122.83608620000001}, {"name": "Troon Foutain East", "vicinity": "15001 NW Troon Dr, Portland", "types": ["point_of_interest", "establishment"], "lat": 45.5485771, "lng": -122.83161889999997}, {"name": "Northridge Woods Natural Area", "vicinity": "Beaverton", "types": ["park", "point_of_interest", "establishment"], "lat": 45.4629718, "lng": -122.87475089999998}, {"name": "Jackson Bottom Wetlands Preserve", "vicinity": "770 S 1st Ave, Hillsboro", "types": ["park", "point_of_interest", "establishment"], "lat": 45.5129854, "lng": -122.99028120000003}, {"name": "Rice Northwest Museum of Rocks & Minerals", "vicinity": "26385 NW Groveland Dr, Hillsboro", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 45.5743029, "lng": -122.94771739999999}, {"name": "Rood Bridge Park", "vicinity": "4000 SE Rood Bridge Rd, Hillsboro", "types": ["park", "point_of_interest", "establishment"], "lat": 45.4927928, "lng": -122.95037609999997}, {"name": "Cooper Mountain Nature Park", "vicinity": "18895 SW Kemmer Rd, Beaverton", "types": ["park", "point_of_interest", "establishment"], "lat": 45.4523234, "lng": -122.8711174}] }
            attractionImages={ {"Welcome To South Hillsboro":image0,"Troon Fountain West":image1,"Troon Foutain East":image2,"Northridge Woods Natural Area":image3,"Jackson Bottom Wetlands Preserve":image4,"Rice Northwest Museum of Rocks & Minerals":image5,"Rood Bridge Park":image6,"Cooper Mountain Nature Park":image7,} }
       />);
  }
}